import PlainHtml from '@swe/shared/ui-kit/components/plain-html';

import { PolicyKey, usePolicy } from 'common/use-cases/policies';

const TermsOfService = () => {
  const { policy } = usePolicy(PolicyKey.TermsOfService);

  if (!policy?.content) return null;

  return <PlainHtml html={policy.content} />;
};

export { TermsOfService };
